<template>
  <nav
    class="relative flex flex-wrap items-center justify-between px-2 py-3 bg-emerald-500 mb-3"
  >
    <div
      class="container px-4 mx-auto flex flex-wrap items-center justify-between"
    >
      <div
        class="w-full relative flex justify-between lg:w-auto px-4 lg:static lg:block lg:justify-start"
      >
        <div
          v-bind:class="{ hidden: !showMenu, flex: showMenu }"
          class="lg:flex lg:flex-grow items-center"
        >
          <ul class="flex flex-col lg:flex-row list-none ml-auto">
            <li class="nav-item">
              <a
                class="px-3 py-1 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                href="/"
              >
                <span
                  class="ml-2 text-base bg-transparent hover:bg-white text-white hover:text-green-500"
                  >E-Library</span
                >
              </a>
            </li>
            <li class="nav-item">
              <a
                class="px-3 py-1 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                :href="url_front"
              >
                <span
                  class="ml-2 text-base bg-transparent hover:bg-white text-white hover:text-green-500"
                  >{{ txt_front }}</span
                >
              </a>
            </li>
            <!-- <li class="nav-item">
              <a class="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75" href="#pablo">
                <font-awesome-icon icon="fa-solid fa-bars" /><span class="ml-2">Categories</span>
              </a>
            </li> -->
            <!-- <li class="nav-item">
            <a class="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75" href="#pablo">
              <font-awesome-icon icon="fa-solid fa-user-bicycle" /><span class="ml-2">My Book</span>
            </a>
          </li> -->
          </ul>
        </div>
        <button
          class="text-white cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
          type="button"
          v-on:click="toggleNavbar()"
        >
          <!-- Add the style and icon you want using the String format -->
          <font-awesome-icon icon="fa-solid fa-bars" />
        </button>
      </div>
      <div
        v-bind:class="{ hidden: !showMenu, flex: showMenu }"
        class="lg:flex lg:flex-grow items-center"
      >
        <ul class="flex flex-col lg:flex-row list-none ml-auto">
          <li class="nav-item">
            <div v-if="!loggedIn">
              <!-- <a href="https://read.libx.psu.ac.th/front/authen/index.php?action=login"
                class="px-4 py-2 bg-transparent hover:bg-white text-white  hover:text-green-500 border border-white hover:border-transparent rounded">Log in
              </a> -->
              <!-- <a href="https://read.libx.psu.ac.th/callback" -->
              <router-link
                to="/login"
                class="px-4 py-2 bg-transparent hover:bg-white text-white hover:text-green-500 border border-white hover:border-transparent rounded"
                >Log in</router-link
              >
              <!-- <a
                href="/login"
                class="px-4 py-2 bg-transparent hover:bg-white text-white hover:text-green-500 border border-white hover:border-transparent rounded"
                >Log in
              </a> -->
            </div>
            <div v-else>
              <!-- <li class="nav-item">
                <a class="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75" href="#pablo">
                  <span class="ml-2">{{ username }}</span>
                </a>
                <a @click="logout"
                  class="px-4 py-1 bg-transparent hover:bg-white text-white  hover:text-green-500 border border-white hover:border-transparent rounded">Logout
                </a>
              </li> -->
              <li class="nav-item">
                <span
                  class="px-1 py-1 flex items-center font-bold leading-snug text-white"
                >
                  <h1 class="px-2 text-md">{{ username }}</h1>
                  <a
                    @click="logout"
                    class="px-4 py-1 bg-transparent hover:bg-white text-white hover:text-green-500 border border-white hover:border-transparent rounded"
                    style="cursor: pointer"
                    >Logout
                  </a>
                </span>
              </li>
            </div>
          </li>
          <!-- <li>
            <button v-if="!loggedIn">Login</button>
            <button v-else @click="logout">Logout</button>
          </li> -->
          <!-- <li class="nav-item">
            <a class="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75" href="#pablo">
              <font-awesome-icon icon="fa-solid fa-bars" /><span class="ml-2">Tweet</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75" href="#pablo">
              <font-awesome-icon icon="fa-solid fa-user-bicycle" /><span class="ml-2">Pin</span>
            </a>
          </li> -->
        </ul>
      </div>
    </div>
  </nav>
  <Carousel :autoplay="5000" :wrap-around="true">
    <slide v-for="slide in slides" :key="slide">
      <img :src="slide" alt="slide {{ index+1 }}" />
    </slide>

    <template #addons>
      <navigation />
      <pagination />
    </template>
  </Carousel>
  <!-- <div class="mx-auto bg-emerald-500 rounded-md shadow border p-2 m-5">
     <h1 class="text-2xl text-white font-bold ">
       Bookcaze
     </h1>
  </div> -->
  <!-- <div class="mx-2 my-3 grid lg:grid-cols-6 md:grid-cols-4 gap-4"> -->
  <!-- <div v-for="book_detail in books_bookcaze" :key="book_detail">
      <div class="max-w-sm rounded overflow-hidden shadow-lg hover:shadow-gray-400">
        <a :href="book_detail.book_url" rel="noreferrer">
        <img class="w-full p-4" :src="book_detail.book_cover" alt="Sunset in the mountains">
        <div class="px-6 py-4">
          <div class="font-bold font-display text-sm mb-2">
            <div v-if="book_detail.book_title.length<26">{{ book_detail.book_title }}</div>
            <div v-if="book_detail.book_title.length>=26">{{ book_detail.book_title.substring(0,25)+".." }}</div>
          </div>
        </div> -->
  <!-- <div class="px-6 pt-4 pb-2">
          <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#photography</span>
          <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#travel</span>
          <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#winter</span>
        </div> -->
  <!-- </a>
      </div>
    </div> -->
  <!-- </div> -->
  <!-- <div class="mx-auto p-2 m-5">
    <a href="https://psulib.bookcaze.com/" class="bg-emerald-500 hover:bg-emerald-700 font-display text-white text-lg font-bold py-2 px-7 rounded-full">
      ดูทั้งหมด
    </a>
  </div> -->
  <div class="mx-auto bg-teal-700 rounded-md shadow border p-2 m-5">
    <h1 class="text-2xl text-white font-bold">CAMBRIDGE</h1>
  </div>
  <div class="mx-2 my-3 grid lg:grid-cols-4 md:grid-cols-4 gap-4">
    <div v-for="top_book in top_books" :key="top_book">
      <div
        class="max-w-sm rounded overflow-hidden shadow-lg hover:shadow-gray-400"
      >
        <a :href="url + top_book.bookid" rel="noreferrer">
          <div class="px-1">
            <img
              class="inline-block object-scale-down h-80 px-3"
              :src="top_book.coverfileurl"
            />
          </div>
          <div class="px-1 py-4">
            <div class="font-bold font-display text-sm mb-2">
              <div v-if="top_book.title.length < 17">{{ top_book.title }}</div>
              <div v-if="top_book.title.length >= 17">
                {{ top_book.title.substring(0, 20) + ".." }}
              </div>
            </div>
          </div>
          <!-- <div class="px-6 pt-4 pb-2">
          <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#photography</span>
          <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#travel</span>
          <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#winter</span>
        </div> -->
        </a>
      </div>
    </div>
  </div>
  <div class="mx-auto p-2 m-5">
    <!-- <a href="https://read.libx.psu.ac.th/front//" -->
    <a
      href="https://test-read.libx.psu.ac.th/front//"
      class="bg-teal-700 hover:bg-teal-500 text-white text-lg font-display font-bold py-2 px-6 rounded-full"
    >
      ดูทั้งหมด
    </a>
  </div>
  <div class="container mx-auto bg-gray-200 rounded-md shadow border p-2 mt-5">
    <div class="h-56 grid grid-cols-4 gap-2 content-start">
      <div class="justify-self-center">
        <!-- <img src="https://read.libx.psu.ac.th/front/images/eLibrary_logo.png" -->
        <img
          src="https://test-read.libx.psu.ac.th/front/images/eLibrary_logo.png"
          class="object-cover w-64 mt-2"
        />
      </div>

      <div>
        <h2
          class="mb-2 text-lg font-semibold font-display text-gray-900 dark:text-white"
        >
          เกี่ยวกับระบบ
        </h2>
        <ul
          class="justify-self-start max-w-md space-y-1 font-display text-gray-500 list-disc list-inside dark:text-gray-400"
        >
          <li class="flex items-start">
            <a href="#" class="nav-link p-0 text-muted"
              >ข้อกำหนดในการเข้าใช้บริการระบบ PSU eLibrary</a
            >
          </li>
          <li class="flex items-start">
            <a href="#" class="nav-link p-0 text-muted"
              >เว็บไซต์สำนักทรัพยากรการเรียนรู้ คุณหญิงหลงฯ</a
            >
          </li>
        </ul>
      </div>

      <div class="justify-self-center">
        <h2
          class="mb-2 text-lg font-semibold font-display text-gray-900 dark:text-white"
        >
          สถิติการเข้าชม
        </h2>
        <li class="flex mt-2">
          <img
            class="mx-auto"
            src="https://hitwebcounter.com/counter/counter.php?page=8064546&style=0006&nbdigits=5&type=ip&initCount=0"
            title="Free Counter"
            Alt="web counter"
          />
        </li>
      </div>
      <div class="justify-self-center">
        <h2
          class="mb-2 text-lg font-semibold font-display text-gray-900 dark:text-white"
        >
          ช่องทางการติดต่อ
        </h2>
        <a
          class="text-muted mx-2 text-gray-600 hover:text-blue-600"
          href="https://www.facebook.com/clibpsuhatyai/"
        >
          <font-awesome-icon :icon="['fab', 'facebook']" size="3x"
        /></a>
        <a class="text-muted mx-2 text-gray-600 hover:text-blue-300" href="#">
          <font-awesome-icon :icon="['fab', 'twitter']" size="3x"
        /></a>
        <a class="text-muted mx-2 text-gray-600 hover:text-pink-700" href="#">
          <font-awesome-icon :icon="['fab', 'instagram']" size="3x"
        /></a>
      </div>
    </div>
  </div>
</template>

<script>
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import axios from "axios";
export default {
  name: "App",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    return {
      slides: [
        // "https://read.libx.psu.ac.th/front/images/banner1.png",
        // "https://read.libx.psu.ac.th/front/images/banner2.png",
        // "https://read.libx.psu.ac.th/front/images/banner3.png",
        "https://test-read.libx.psu.ac.th/front/images/banner1.png",
        "https://test-read.libx.psu.ac.th/front/images/banner2.png",
        "https://test-read.libx.psu.ac.th/front/images/banner3.png",
      ],
      books_bookcaze: [
        {
          book_cover:
            "https://www.bookcaze.com/image/cache/catalog/page1_ZNLP71590634767060Pw5sV-300x400.jpg",
          book_title: "สมุดปกฟ้าอากาศสะอาด (Clean Air Blue Paper)",
          book_url: "https://psulib.bookcaze.com/product/24278/detail",
        },
        {
          book_cover:
            "https://www.bookcaze.com/image/cache/catalog/page1_pAaAm1630923871239ypuxe-300x400.jpg",
          book_title: "เสพติดพนัน ความใหญ่โตของปัญหา และแนวทางแก้ไข",
          book_url: "https://psulib.bookcaze.com/product/25913/detail",
        },
        {
          book_cover:
            "https://www.bookcaze.com/image/cache/catalog/page1_JuLLp1630986138479ElPu9-300x400.jpg",
          book_title: "การวิเคราะห์และบริหารความเสี่ยง",
          book_url: "https://psulib.bookcaze.com/product/25917/detail",
        },
        {
          book_cover:
            "https://www.bookcaze.com/image/cache/catalog/page1_5Ck8H1674193315378gt4g6-300x400.jpg",
          book_title:
            "การแสวงหาโอกาสการค้าสินค้าและบริการระหว่างประเทศ สำหรับผู้ประกอบการ SMEs ไทย จาก China E-commerce",
          book_url: "https://psulib.bookcaze.com/product/28615/detail",
        },
        {
          book_cover:
            "https://www.bookcaze.com/image/cache/catalog/page1_XmL2x16741936517219Ln2K-300x400.jpg",
          book_title:
            "การศึกษาเปรียบเทียบนโยบายและประสิทธิภาพการพัฒนาและส่งเสริมนวัตกรรมและเทคโนโลยีในกลุ่ม SMEs ของประเทศสมาชิกอาเซียน",
          book_url: "https://psulib.bookcaze.com/product/28617/detail",
        },
        {
          book_cover:
            "https://www.bookcaze.com/image/cache/catalog/page1_lanIJ1674198996952aeg3h-300x400.jpg",
          book_title:
            "การประเมินศักยภาพและโอกาสของประเทศสมาชิกอาเซียน ในตลาด ASEAN E-commerce",
          book_url: "https://psulib.bookcaze.com/product/28618/detail",
        },
        {
          book_cover:
            "https://www.bookcaze.com/image/cache/catalog/page1_jKoAG1674211995193o8naS-300x400.jpg",
          book_title:
            "การค้าระหว่างประเทศเพื่อลดความเหลื่อมลํ้าและการเติบโตอย่างสมดุลสำหรับประเทศไทย",
          book_url: "https://psulib.bookcaze.com/product/28655/detail",
        },
        {
          book_cover:
            "https://www.bookcaze.com/image/cache/catalog/page1_Ln6081674443153702TbUdD-300x400.jpg",
          book_title:
            "ผลกระทบจากการเปิดการค้าเสรีระหว่างไทยกับสหภาพยุโรปที่มีต่ออุตสาหกรรมของไทย",
          book_url: "https://psulib.bookcaze.com/product/28656/detail",
        },
        {
          book_cover:
            "https://www.bookcaze.com/image/cache/catalog/page1_M9DGx1674444007921GrDSR-300x400.jpg",
          book_title:
            "สถาบันการเงินในระดับจุลภาค : กรณีศึกษาเปรียบเทียบระหว่างบังกลาเทศ มองโกเลีย และไทย",
          book_url: "https://psulib.bookcaze.com/product/28659/detail",
        },
        {
          book_cover:
            "https://www.bookcaze.com/image/cache/catalog/page1_MnbrF1674447614151zC3hv-300x400.jpg",
          book_title:
            "การพัฒนานโยบายการบริหารจัดการแรงงานข้ามชาติภายใต้ประชาคมอาเซียน เพื่อความมั่นคงทางเศรษฐกิจและสังคมไทย",
          book_url: "https://psulib.bookcaze.com/product/28666/detail",
        },
        {
          book_cover:
            "https://www.bookcaze.com/image/cache/catalog/page1_fO3h51674530075342LlCpI-300x400.jpg",
          book_title:
            "โอกาสและลู่ทางการเข้าสู่ตลาดของธุรกิจอาหารฮาลาลในอินโดนีเซีย (ฉบับปรับปรุง ครั้งที่ 1)",
          book_url: "https://psulib.bookcaze.com/product/25917/detail",
        },
        {
          book_cover:
            "https://www.bookcaze.com/image/cache/catalog/page1_HU1RD16746159121398912e-300x400.jpg",
          book_title: "โอกาสการลงทุนของอุตสาหกรรมไทยในสหภาพเมียนมาร์",
          book_url: "https://psulib.bookcaze.com/product/28711/detail",
        },
      ],
      top_books: [],
      showMenu: false,
      url: "https://test-read.libx.psu.ac.th/front/book/bookdetail.php?",
      username: "",
      txt_front: "",
      url_front: "",
      loggedIn: false,
    };
  },
  methods: {
    toggleNavbar: function () {
      this.showMenu = !this.showMenu;
    },
    fetchBooks() {
      axios
        // .get("https://read.libx.psu.ac.th/backend/book/book_cambridge.php")
        .get("https://test-read.libx.psu.ac.th/backend/book/book_cambridge.php")
        .then((response) => {
          // console.log(response.data);
          this.top_books = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    logout() {
      // Handle logout logic
      localStorage.removeItem("username");
      this.loggedIn = false;
      localStorage.clear();

      var xmlhttp = new XMLHttpRequest();
      xmlhttp.open(
        "GET",
        // "https://read.libx.psu.ac.th/backend/authen/logout.php",
        "https://test-read.libx.psu.ac.th/backend/authen/logout.php",
        true
      );
      xmlhttp.onreadystatechange = function () {
        if (xmlhttp.readyState == 4) {
          if (xmlhttp.status == 200) {
            // console.log(xmlhttp.responseText);
          }
        }
      };
      xmlhttp.send(null);
      location.href = "../../";
    },
    getUsernameCookie() {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i].trim();
        if (cookie.startsWith("username=")) {
          return decodeURIComponent(cookie.substring("username=".length));
        }
      }
      return null; // Return null if the 'username' cookie is not found
    },
  },
  mounted() {
    document.title = "PSU e-Library";

    if (localStorage.getItem("username") !== null) {
      // if(("uid" in localStorage) && ("username" in localStorage)){
      //   this.loggedIn = true;
      //   this.username = localStorage.getItem("fullname_en");
      // }
    }
    console.log("Test cookie");
    // let username = this.getUsernameCookie();
    let username = localStorage.getItem("username");
    if (
      username == "test" ||
      username == "library1" ||
      username == "library2"
    ) {
      console.log("Username from cookie:", username);
      this.loggedIn = true;
      this.username = username;
      this.txt_front = "หน้าหลัก";
      // this.url_front = "https://read.libx.psu.ac.th/front/home/";
      this.url_front = "../front/home/";
    } else {
      console.log("Username cookie not found.");
    }

    // axios.get('https://read.libx.psu.ac.th/psupassportcallback/check_auth_front.php')
    // .then((response) => {
    //   var data = response.data;
    //   // console.log(data)
    //   if (data.loggedIn == true) {

    //     // console.log('Logged In');
    //     // console.log('User Profile:');
    //     // console.log(data.user);
    //     // console.log('Token is: ' + data.accessToken);
    //     // console.log('Refresh Token is: ' + data.refreshToken);
    //     // console.log('Expires in: ' + data.expiresIn);
    //     this.loggedIn = true;
    //     this.username = data.fullname_en;
    //     this.txt_front = 'หน้าหลัก';
    //     this.url_front = 'https://read.libx.psu.ac.th/front/home/';
    //     // location.href = '/'+data.userid;

    //     // ... Handle the logged-in state
    //   } else {

    //     // console.log('Not logged in');
    //     // ... Handle the not logged-in state
    //   }
    // })
    // .catch(function(error) {
    //   console.error(error);
    //   // ... Handle the error case
    // });

    this.fetchBooks();
  },
};
</script>
