import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import AboutView from "../views/AboutView.vue";
const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "Read LibX",
    },
  },
  {
    path: "/:id",
    name: "DirectFront",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ViewLibx.vue"),
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: AboutView,
  },
  {
    path: "/carousel",
    name: "carousel",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/CarouselView.vue"),
  },
  {
    path: "/navbar",
    name: "navbar",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/NavbarView.vue"),
  },
  {
    path: "/authen",
    name: "authen",
    component: () => import("../views/AuthenView.vue"),
  },
  {
    path: "/psupassportcallback",
    name: "psupassportcallback",
    component: () => import("../views/AuthenView.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LoginView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
